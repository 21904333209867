import { Component, OnInit, Inject, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

import { FormaPagtoService } from "src/app/services/forma-pagto.service";
import { NotificationService } from "src/app/core/notification/notification-service.service";
import { isNull } from '@angular/compiler/src/output/output_ast';

@Component({
  selector: 'app-pix-payment-dialog',
  templateUrl: './pix-payment-dialog.component.html',
  styleUrls: ['./pix-payment-dialog.component.scss']
})
export class PixPaymentDialogComponent implements OnInit {
  constructor (
    @Inject(MAT_DIALOG_DATA) public data: any,
    public dialogRef: MatDialogRef<PixPaymentDialogComponent>,
    public changeRef: ChangeDetectorRef,
    private formaPagto: FormaPagtoService,
    private toastr: NotificationService,
  ) { }

  event: EventEmitter<any> = new EventEmitter();
  iFrame: HTMLIFrameElement;

  btnRefreshLock: boolean = false;
  btnCancelLock: boolean = false;
  interruptAwait: boolean = false;

  lancamento: number;

  timeBtnGet: number = 5;
  timeAutoGet: number = 40;

  async ngOnInit() {
    this.iFrame = <HTMLIFrameElement>document.getElementById("IframePix");
    this.lancamento = this.data.lancamento;
    let returnLanc = await this.getLanc(this.lancamento);
    if (!returnLanc) {
      this.autoGet(this.timeAutoGet);
    } else {
      this.lockBtn(true);
      this.pixPago();
    }
  }

  public async clickRefresh() {
    this.interruptAwait = true;
    let returnPgto = await this.getLanc(this.lancamento);
    if (!returnPgto) {
      this.btnGet(this.timeBtnGet);
      this.autoGet(this.timeAutoGet);
    } else {
      this.lockBtn(true);
      this.pixPago();
    }
  }

  public clickCancel() {
    this.lockBtn(true);
    this.interruptAwait = true
    this.event.emit(false);
    this.dialogRef.close();
  }

  public autoGet(totalTime: number) {
    const progressBarEl = <HTMLDivElement>document.querySelector(".progress");
    var remainingTime = totalTime;
    (async () => {
      var i = 0;
      while (await new Promise(resolve => setTimeout(() => { resolve(i++) }, 1000)) < 1000) {
        if (!this.interruptAwait) {
          if (remainingTime >= 0) {
            const progress = (100 * remainingTime) / totalTime;
            progressBarEl.style.width = `${progress}%`;
            remainingTime -= 1;
          } else {
            let returnPgto = await this.getLanc(this.lancamento);
            if (!returnPgto) {
              this.autoGet(this.timeAutoGet);
              break
            } else {
              this.pixPago();
              break
            }
          }
        } else {
          this.interruptAwait = false;
          break
        }
      }
    })();
  }

  public btnGet(time: number) {
    this.btnRefreshLock = true;
    (async () => {
      var i = 0;
      while (await new Promise(resolve => setTimeout(() => { resolve(i++) }, 1000)) < 100) {
        time -= 1;
        if (time < 0) {
          this.btnRefreshLock = false;
          break
        }
      }
    })();
  }

  public lockBtn(status: boolean) {
    this.btnRefreshLock = status;
    this.btnCancelLock = status;
  }

  public async getLanc(id_lancamento: number) {
    return new Promise((resolve) => {
      this.formaPagto.consultarLancamento(id_lancamento).subscribe(
        (dados) => {
          this.iFrame.src = dados.object.linkBoleto + "?formato=pix";
          if ((dados.object.valor != null && dados.object.valor_pago != null) && (dados.object.valor_pago == dados.object.valor)) {
            resolve(true);
          } else {
            resolve(false);
          }
        }
      );
    });
  }

  public pixPago() {
    this.toastr.showSuccess("Pix pago com sucesso", "Sucesso");
    this.event.emit(true);
    this.dialogRef.close();
  }
}

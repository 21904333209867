import { Injectable } from '@angular/core';
import { BaseServicePadrao } from '../core/base-service-padrao';
import { Http } from '@angular/http';
import { timeout, map, catchError } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class FormaPagtoService extends BaseServicePadrao {

  urlBase: string;
  constructor (private http: Http) {
    super(http, "formaDePagto");
  }

  emitirBoleto(calcularTotal: string, doador: object) {
    this.checkToken();
    return this.http.post(this.urlBase + "/emitirBoleto/" + calcularTotal, doador, this.options)
      .pipe(timeout(30000),
        map(dados => this.wsHandler.handle(dados)), catchError(this.handleError));
  }

  gerarTokenCartao(cartao: object) {
    this.checkToken();
    return this.http.post(this.urlBase + "/gerarTokenCartao/", cartao, this.options)
      .pipe(timeout(30000),
        map(dados => this.wsHandler.handle(dados)), catchError(this.handleError));
  }

  gerarCobrancaCartao(cartaoToken: object) {
    this.checkToken();
    return this.http.post(this.urlBase + "/gerarCobrancaCartao/", cartaoToken, this.options)
      .pipe(timeout(30000),
        map(dados => this.wsHandler.handle(dados)), catchError(this.handleError));
  }

  consultarLancamento(id_lancamento: number) {
    this.checkToken();
    return this.http.get(this.urlBase + "/consultarLancamento/" + id_lancamento, this.options)
      .pipe(timeout(30000),
        map(dados => this.wsHandler.handle(dados)), catchError(this.handleError));
  }

  getVoucherById(id_voucher: string) {
    this.checkToken();
    return this.http.get(this.urlBase + "/getVoucherById/" + id_voucher, this.options)
      .pipe(timeout(30000),
        map(dados => this.wsHandler.handle(dados)), catchError(this.handleError));
  }

  getVoucherByCpf(cpf: string) {
    this.checkToken();
    return this.http.get(this.urlBase + "/getVoucherByCpf/" + cpf, this.options)
      .pipe(timeout(30000),
        map(dados => this.wsHandler.handle(dados)), catchError(this.handleError));
  }
}

import { TextMaskModule } from 'angular2-text-mask';
import { ToastrModule } from 'ngx-toastr';

import { APP_BASE_HREF, DatePipe, HashLocationStrategy, LocationStrategy, registerLocaleData } from '@angular/common';
import localePt from '@angular/common/locales/pt';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpModule } from '@angular/http';
import { MAT_DATE_LOCALE } from '@angular/material';
import { MatSliderModule } from '@angular/material/slider';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';

import { AuthGuard } from './_auth/auth.guard';
import { AuthService } from './_auth/auth.service';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AppConfig } from './app.config';
import { Error404Component } from './components/error404/error404.component';
import { LayoutsModule } from './components/layouts/layouts.module';
import { DirectivesModule } from './core/directives/directives.module';
import { CustomMaterialModule } from './core/modules/material.module';
import { NotificationService } from './core/notification/notification-service.service';
import { PipeModule } from './core/pipe/pipe.module';
import { SessionStorageControle } from './core/session-storage-controle';
import { ChangePasswordDialogComponent } from './core/shared/change-password-dialog/change-password-dialog.component';
import { ChangePasswordDialogService } from './core/shared/change-password-dialog/change-password-dialog.service';
import { ConfirmationDialogComponent } from './core/shared/confirmation-dialog/confirmation-dialog.component';
import { ConfirmationDialogService } from './core/shared/confirmation-dialog/confirmation-dialog.service';
import { PopupDialogComponent } from './core/shared/popup-dialog/popup-dialog.component';
import { PopupDialogService } from './core/shared/popup-dialog/popup-dialog.service';
import { MessageDialogComponent } from './core/shared/message-dialog/message-dialog.component';
import { MessageDialogService } from './core/shared/message-dialog/message-dialog.service';
import { ResetPasswordDialogComponent } from './core/shared/reset-password-dialog/reset-password-dialog.component';
import { ResetPasswordDialogService } from './core/shared/reset-password-dialog/reset-password-dialog.service';
import { PixPaymentDialogComponent } from './core/shared/pix-payment-dialog/pix-payment-dialog.component';
import { PixPaymentDialogService } from './core/shared/pix-payment-dialog/pix-payment-dialog.service';
import { VouchersDialogComponent } from './core/shared/vouchers-dialog/vouchers-dialog.component';
import { VouchersDialogService } from './core/shared/vouchers-dialog/vouchers-dialog.service';
import { MenuService } from './services/menu.service';
import { OAuthService } from './services/oauth.service';

registerLocaleData(localePt, 'pt');
@NgModule({
  declarations: [

    AppComponent,

    Error404Component,

    ConfirmationDialogComponent,

    PopupDialogComponent,

    MessageDialogComponent,

    ResetPasswordDialogComponent,

    ChangePasswordDialogComponent,

    PixPaymentDialogComponent,

    VouchersDialogComponent
  ],
  imports: [
    BrowserModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    PipeModule,
    HttpModule,
    RouterModule,
    TextMaskModule,
    CustomMaterialModule,
    FlexLayoutModule,
    LayoutsModule,
    MatSliderModule,
    DirectivesModule,
    ToastrModule.forRoot(
      {
        positionClass: 'toast-bottom-right',
        preventDuplicates: true
      }), // ToastrModule added


    AppRoutingModule,

  ],
  entryComponents: [
    ConfirmationDialogComponent,
    PopupDialogComponent,
    MessageDialogComponent,
    ResetPasswordDialogComponent,
    ChangePasswordDialogComponent,
    PixPaymentDialogComponent,
    VouchersDialogComponent,
  ],

  providers: [
    OAuthService,
    NotificationService,
    AuthGuard,
    AuthService,
    MenuService,
    ConfirmationDialogService,
    PopupDialogService,
    MessageDialogService,
    ResetPasswordDialogService,
    ChangePasswordDialogService,
    PixPaymentDialogService,
    VouchersDialogService,
    SessionStorageControle,
    AppConfig,
    {

      provide: APP_INITIALIZER,
      useFactory: (config: AppConfig) => () => config.load(),
      deps: [AppConfig],
      multi: true
    },
    {
      provide: MAT_DATE_LOCALE,
      useValue: 'pt-br'
    },
    {
      provide: LOCALE_ID,
      useValue: 'pt'
    },
    {
      provide: APP_BASE_HREF,
      useValue: '/'
    },
    {
      provide: LocationStrategy, useClass: HashLocationStrategy
    }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }

import { Component } from '@angular/core';
import { AppConfig } from './app.config';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'innovatox-sistema-ui';

  logout: boolean = false;

  constructor(public config: AppConfig) {}

    ngOnInit(){
      if ( (this.config.getEnv('env') =='production') || (this.config.getEnv('env') =='homolog') ) {
        if (location.protocol === 'http:') {
          window.location.href = location.href.replace('http', 'https');
        }
      }
    }
}


import { Injectable } from "@angular/core";
import { MatDialog } from '@angular/material';

import { PixPaymentDialogComponent } from './pix-payment-dialog.component';

@Injectable()

export class PixPaymentDialogService {

  constructor (public dialog: MatDialog) { }

  OpenPix(lancamento: number) {
    const dialogRef = this.dialog.open(PixPaymentDialogComponent, {
      data: {
        lancamento: lancamento
      },
      disableClose: true
    });

    dialogRef.componentInstance.changeRef.markForCheck();
    return dialogRef.componentInstance.event;
  }
}